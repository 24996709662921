const techURLs = new Map();

techURLs.set('react', 'https://reactjs.org/');
techURLs.set('reactjs', 'https://reactjs.org/');
techURLs.set('angular', 'https://angularjs.org/');
techURLs.set('angularjs', 'https://angularjs.org/');
techURLs.set('c++', 'https://isocpp.org/');
techURLs.set('cordova', 'https://cordova.apache.org');
techURLs.set('remarkable', 'https://remarkable.com/');
techURLs.set('tween', 'https://github.com/sanbox-irl/tween');

techURLs.set('marko', 'https://markojs.com/');
techURLs.set('basscss', 'https://basscss.com/');
techURLs.set('lasso', 'https://github.com/lasso-js/lasso');
techURLs.set('lasso.js', 'https://github.com/lasso-js/lasso');
techURLs.set('marko.js', 'https://markojs.com/');

techURLs.set('yoffee', 'https://yoffee.netlify.app/');
techURLs.set('ngraph', 'https://github.com/anvaka/ngraph');

techURLs.set('three.js', 'https://threejs.org');
techURLs.set('three', 'https://threejs.org');

techURLs.set('vanilla js', 'http://vanilla-js.com/');
techURLs.set('sass', 'https://sass-lang.com/');
techURLs.set('sketch', 'https://sketch.com/');
techURLs.set('rails', 'https://rubyonrails.org/');

techURLs.set('aws', 'https://aws.amazon.com/');
techURLs.set('flutter', 'https://flutter.dev/');

techURLs.set('typescript', 'https://typescriptlang.org/');
techURLs.set('webgl', 'https://khronos.org/webgl/');
techURLs.set('css', 'https://developer.mozilla.org/en-US/docs/Web/CSS');

techURLs.set('parcel', 'https://parceljs.org/');
techURLs.set('web components', 'https://webcomponents.org/');
techURLs.set('web3.storage', 'https://web3.storage/');

techURLs.set('inferno', 'https://infernojs.org/');
techURLs.set('mincss', 'https://mincss.com/');
techURLs.set('redux', 'https://redux.js.org/');
techURLs.set('netflix-roulette', 'https://github.com/hirako2000/netflixroulette-js');
techURLs.set('tone.js', 'https://tonejs.github.io/');
techURLs.set(
  '2d canvas',
  'https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D',
);

techURLs.set('golang', 'https://go.dev/');
techURLs.set('docker', 'https://docker.com/');
techURLs.set('node.js', 'https://nodejs.org/');
techURLs.set('rust', 'https://www.rust-lang.org/');

techURLs.set('javascript', 'https://developer.mozilla.org/en-US/docs/Web/JavaScript');
techURLs.set('svg', 'https://developer.mozilla.org/en-US/docs/Web/SVG');
techURLs.set('wasm', 'https://webassembly.org/');
techURLs.set('webassembly', 'https://webassembly.org/');

techURLs.set('math', 'https://en.wikipedia.org/wiki/Mathematics');

export const getTechURL = tech => {
  if (tech && techURLs.has(tech.toLowerCase())) {
    return techURLs.get(tech.toLowerCase());
  } else {
    return null;
  }
};
