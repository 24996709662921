import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 512 512">
    <title>Logo</title>
    <g>
	<path fill="#fc2052" d="M491.5,0h-471C9.2,0,0,9.2,0,20.5v471C0,502.8,9.2,512,20.5,512h471c11.3,0,20.5-9.2,20.5-20.5v-471
		C512,9.2,502.8,0,491.5,0z M256.7,259.9l-122.9,103c-3.3,2.8-8.4,0.4-8.4-3.9v-40.1c0-1.5,0.7-2.9,1.9-3.9l70.3-59l-70.3-59
		c-1.2-0.9-1.9-2.4-1.9-3.9V153c0-4.4,5.1-6.7,8.4-3.9l122.9,103C259.2,254.1,259.2,257.9,256.7,259.9z M386.6,359
		c0,2.8-2.2,5.1-4.8,5.1H263.4c-2.6,0-4.8-2.3-4.8-5.1v-30.7c0-2.8,2.2-5.1,4.8-5.1h118.4c2.6,0,4.8,2.3,4.8,5.1V359z"/>
</g>
  </svg>
);

export default IconLogo;
