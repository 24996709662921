import { css } from 'styled-components';

import CalibreRegularWoff from '@fonts/Calibre/Calibre-Regular.woff';
import CalibreRegularWoff2 from '@fonts/Calibre/Calibre-Regular.woff2';
import CalibreMediumWoff from '@fonts/Calibre/Calibre-Medium.woff';
import CalibreMediumWoff2 from '@fonts/Calibre/Calibre-Medium.woff2';
import CalibreSemiboldWoff from '@fonts/Calibre/Calibre-Semibold.woff';
import CalibreSemiboldWoff2 from '@fonts/Calibre/Calibre-Semibold.woff2';

import CalibreRegularItalicWoff from '@fonts/Calibre/Calibre-RegularItalic.woff';
import CalibreRegularItalicWoff2 from '@fonts/Calibre/Calibre-RegularItalic.woff2';
import CalibreMediumItalicWoff from '@fonts/Calibre/Calibre-MediumItalic.woff';
import CalibreMediumItalicWoff2 from '@fonts/Calibre/Calibre-MediumItalic.woff2';
import CalibreSemiboldItalicWoff from '@fonts/Calibre/Calibre-SemiboldItalic.woff';
import CalibreSemiboldItalicWoff2 from '@fonts/Calibre/Calibre-SemiboldItalic.woff2';

import TifiWoff from '@fonts/Tifi/NotoSansTifinagh-Regular.woff';
import TifiWoff2 from '@fonts/Tifi/NotoSansTifinagh-Regular.woff2';

import AmericanaWoff from '@fonts/Americana/americana.woff';
import AmericanaWoff2 from '@fonts/Americana/americana.woff2';

import GaramondRegularWoff2 from '@fonts/Garamond/CormorantGaramond-Regular.woff2';
import GaramondLightWoff2 from '@fonts/Garamond/CormorantGaramond-Light.woff2';

import DosisRegularWoff from '@fonts/Dosis/Dosis-Regular.woff';
import DosisRegularWoff2 from '@fonts/Dosis/Dosis-Regular.woff2';
import DosisLightWoff from '@fonts/Dosis/Dosis-Light.woff';
import DosisLightWoff2 from '@fonts/Dosis/Dosis-Light.woff2';

const tifiNormalWeight = {
  400: [TifiWoff, TifiWoff2],
};

const americanaWeight = {
  400: [AmericanaWoff, AmericanaWoff2],
};

const garamondWeights = {
  200: [GaramondLightWoff2],
  400: [GaramondRegularWoff2],
};

const dosisWeights = {
  200: [DosisLightWoff, DosisLightWoff2],
  400: [DosisRegularWoff, DosisRegularWoff2],
};

const calibreNormalWeights = {
  400: [CalibreRegularWoff, CalibreRegularWoff2],
  500: [CalibreMediumWoff, CalibreMediumWoff2],
  600: [CalibreSemiboldWoff, CalibreSemiboldWoff2],
};

const calibreItalicWeights = {
  400: [CalibreRegularItalicWoff, CalibreRegularItalicWoff2],
  500: [CalibreMediumItalicWoff, CalibreMediumItalicWoff2],
  600: [CalibreSemiboldItalicWoff, CalibreSemiboldItalicWoff2],
};

const tifi = {
  name: 'Noto Sans Tifinagh',
  normal: tifiNormalWeight,
};

const americana = {
  name: 'Americana',
  normal: americanaWeight,
};

const garamond = {
  name: 'Garamond',
  normal: garamondWeights,
};

const dosis = {
  name: 'Dosis',
  normal: dosisWeights,
};

const calibre = {
  name: 'Calibre',
  normal: calibreNormalWeights,
  italic: calibreItalicWeights,
};

const createFontFaces = (family, style = 'normal') => {
  let styles = '';

  for (const [weight, formats] of Object.entries(family[style])) {
    const woff = formats[0];
    const woff2 = formats[1];

    styles += `
      @font-face {
        font-family: '${family.name}';
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
  }

  return styles;
};

const calibreNormal = createFontFaces(calibre);
const calibreItalic = createFontFaces(calibre, 'italic');

const tifiNormal = createFontFaces(tifi);

const americanaNormal = createFontFaces(americana);

const garamondNormal = createFontFaces(garamond);

const dosisNormal = createFontFaces(dosis);

const Fonts = css`
  ${calibreNormal + calibreItalic + tifiNormal + americanaNormal + garamondNormal + dosisNormal}
`;

export default Fonts;
