import { css } from 'styled-components';

// https://reactcommunity.org/react-transition-group/css-transition

const TransitionStyles = css`
  /* Fade up */
  .fadeup-enter {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms var(--easing), transform 300ms var(--easing);
  }

  .fadeup-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms var(--easing), transform 300ms var(--easing);
  }

  /* Fade down */
  .fadedown-enter {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms var(--easing), transform 300ms var(--easing);
  }

  .fadedown-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms var(--easing), transform 300ms var(--easing);
  }

  /* Fade */
  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms var(--easing);
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms var(--easing);
  }

  .glitching {
    position: relative;
}

.glitching.title {
    text-shadow: 2px 1px black;
}

.glitching.centered {
    width: 100%;
}


@keyframes noise-anim {
    0% {
        clip: rect(92px, 9999px, 87px, 0);
    }
    10% {
        clip: rect(74px, 9999px, 16px, 0);
    }
    20% {
        clip: rect(11px, 9999px, 78px, 0);
    }
    30% {
        clip: rect(14px, 9999px, 98px, 0);
    }
    40% {
        clip: rect(10px, 9999px, 25px, 0);
    }
    50% {
        clip: rect(98px, 9999px, 100px, 0);
    }
    60% {
        clip: rect(92px, 9999px, 4px, 0);
    }
    70% {
        clip: rect(96px, 9999px, 28px, 0);
    }
    80% {
        clip: rect(65px, 9999px, 9px, 0);
    }
    90% {
        clip: rect(50px, 9999px, 44px, 0);
    }
    100% {
        clip: rect(70px, 9999px, 95px, 0);
    }
}
.glitching:after {
    content: attr(data-text);
    position: absolute;
    left: -1px;
    text-shadow: 1px 0 black;
    top: 0;
    background: transparent;
    overflow: hidden;
    animation: noise-anim 1.5s infinite linear alternate-reverse;
    width: 100%;
}

.glitching:after {
    content: attr(data-text);
    position: absolute;
    left: -3px;
    text-shadow: 1px 0 black;
    top: 0;
    background: transparent;
    overflow: hidden;
    animation: noise-anim 1s infinite linear alternate-reverse;
    width: 100%;
    filter: blur(1px);
  }
`;

export default TransitionStyles;
