import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { getTechURL } from '../../tech';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }

  p > a {
    color: inherit;

    &hover {
      color: inherit;
      text-decoration: overline;
    }
  }

  .go {
    color: var(--green);
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        //content: '▹';
        content: 'ⴾ';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
        top: 5px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);
    //background-color: #f4c1cd;
    background-color: #a8b2d1;

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'JavaScript',
    'TypeScript',
    'React',
    'Node.js',
    'Rust',
    'Golang',
    'WebAssembly',
    'Docker',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading two">About</h2>

      <div className="inner">
        <StyledText>
          <h5>Me</h5>
          <div>
            <p>
              <string>Believe</string> and thrive on being driven and self-motivated, constantly
              exploring new technologies and practices.
            </p>

            <p>
              Currently, my primary focus revolves around producing great UX, and decentralizing
              tech.
            </p>

            <p>
              Here are some of the technologies I've been delving into and gaining more experience
              with lately
            </p>
          </div>

          <ul className="skills-list">
            {skills &&
              skills.map((skill, i) => (
                <li key={i}>
                  <a href={getTechURL(skill)}>{skill}</a>
                </li>
              ))}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
      <StyledText>
        <br />
        <br />
        <hr />
        <br />
        <h5>This site</h5>
        <div>
          <p>
            Built with passion. <a href="https://codepen.io/mburakerman/pen/MpKjjj">Touches</a> of{' '}
            <a href="https://brittanychiang.com/">inspiration</a>. And dedication. Nothing from
            another planet about it.
          </p>

          <p>
            There is a blog in there. Get to it via{' '}
            <a className="go" href="/ok/">
              /ok
            </a>
            .
          </p>

          <p>
            If you need a job, or need someone, either way. Then do get in touch! You <i>may</i> be
            surprised.
          </p>
        </div>
      </StyledText>
    </StyledAboutSection>
  );
};

export default About;
