import { css } from 'styled-components';

const variables = css`
  :root {
    --dark-navy: #020c1b;
    --navy: #1d1d1d;
    --navy-op: #8e8e8e;
    //--light-navy: #112240;
    --light-navy: #212121;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2, 12, 27, 0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --slate-op: #515c7b;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    //--green: #64ffda;
    --green: #fc2053;
    --green-tint: rgba(100, 255, 218, 0.1);
    --pink: #f57dff;
    --blue: #57cbff;

    --dark-green: #181818;

    --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;
    --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    --font-tifi: 'Noto Sans Tifinagh', 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

    --font-dosis: 'Dosis', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;

    --font-americana: 'Americana', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;

    --font-garamond: 'Garamond', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
      sans-serif;

    --fz-tiny: 8px;
    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;

    --border-radius: 0px; //4
    --nav-height: 100px;
    --nav-scroll-height: 70px;

    --tab-height: 42px;
    --tab-width: 90px;

    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);

    --hamburger-width: 30px;

    --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    --ham-after-active: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }
`;

export default variables;
